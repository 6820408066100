<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Product Categorie">

                <b-button v-if="$hasPermission($permissions.AddProducts)" variant="primary" @click="addProductCategoryModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="productCategories" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditProducts)" variant="warning" @click="openProductCategoryEdit(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveProducts)" variant="danger" @click="removeProductCategory(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <div class="rounded-sm mr-1" :style="{width: '60px', height: '25px', backgroundColor: props.formattedRow[props.column.field] }"></div>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>

            <b-modal title="Add product category" v-model="addProductCategoryModalActive" no-close-on-backdrop>
                <template #default>
                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="addObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="addObject.color"/>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="addProductCategoryModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="primary" @click="addProductCategory">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add</span>
                    </b-button>
                </template>
            </b-modal>

            <b-modal title="Edit product category" v-model="editProductCategoryModalActive" no-close-on-backdrop>
                <template #default>
                    <b-form-group>
                        <label>Name</label>
                        <b-form-input v-model="editObject.name"/>
                    </b-form-group>

                    <ColorPicker v-model="editObject.color"/>
                </template>

                <template #modal-footer>

                    <b-button variant="danger" @click="editProductCategoryModalActive = false">
                        <feather-icon
                            icon="XIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Close</span>
                    </b-button>

                    <b-button variant="warning" @click="saveProductCategory">
                        <feather-icon
                            icon="SaveIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Save</span>
                    </b-button>
                </template>
            </b-modal>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BFormGroup, BFormInput} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import ColorPicker from '@/views/components/ColorPicker'

    export default {
        components: {
            ColorPicker,
            BCard,
            BOverlay,
            BasicTable,
            BFormGroup,
            BFormInput,
            BButton
        },
        data() {
            return {
                dataLoaded: false,
                productCategories: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Color',
                        displayType: 2,
                        field: 'color',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {name: '', color: '#000000'},
                addProductCategoryModalActive: false,

                editObject: {name: '', color: '#000000'},
                editProductCategoryModalActive: false
            }
        },
        methods: {
            openProductCategoryEdit(productCategory) {
                this.editObject = JSON.parse(JSON.stringify(productCategory))
                this.editProductCategoryModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/product_category')
                loadPromise.then(function(response) {
                    thisIns.productCategories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addProductCategory() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/product_category', this.addObject)
                addPromise.then(function() {
                    thisIns.addProductCategoryModalActive = false
                    thisIns.$printSuccess('Product category added')
                    thisIns.addObject = {name: ''}
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            saveProductCategory() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/product_category/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.editProductCategoryModalActive = false
                    thisIns.$printSuccess('Product category saved')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            async removeProductCategory(id) {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to delete this product category?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/product_category/${  id}`)
                    this.$printSuccess('Product category removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                } finally {
                    this.loadData()
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>